<template>
  <ul class="product-range-filter">
    <li v-for="(filter, filterName) in value" :key="filter.label" class="product-range-filter__list-item">
      <button
        type="button"
        class="product-range-filter__item"
        :class="[
          { 'product-range-filter__item--active': filter.value },
          `product-range-filter__item--${filterName.replace(/_/g, '-')}`,
        ]"
        :aria-pressed="filter.value"
        data-test="category-filter-button"
        @click="toggleFilter(filter, filterName)"
        @keyup.space="toggleFilter(filter, filterName)"
      >
        <NuxtIcon aria-hidden="true" name="scribble" filled class="product-range-filter__item-scribble" />
        {{ $t(`product_filters.${filterName}`) }}
      </button>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { useNuxtApp } from 'nuxt/app';
import { type PropType } from 'vue';
import { type ProductFilter, type ProductFilterOption } from '@/types/Product';

const props = defineProps({
  value: {
    type: Object as PropType<ProductFilter>,
    required: true,
  },
});

const { $bus } = useNuxtApp();
const emit = defineEmits(['input']);

const toggleFilter = (filter?: ProductFilterOption, filterName = '') => {
  if (filter) {
    const newValue = JSON.parse(JSON.stringify(props.value));
    newValue[filterName].value = !filter.value;
    emit('input', newValue);
    $bus.$emit('product-filter:change');
  }
};
</script>

<style lang="scss" scoped>
.product-range-filter {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  color: $white;

  @include respond-min($desktop) {
    max-inline-size: 800px;
  }

  @include respond-min($desktop-xl) {
    flex-wrap: nowrap;
  }

  &__list-item {
    @include respond-max($tablet) {
      display: flex;
      inline-size: 100%;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: calc(50% - 24px);
    align-items: center;
    justify-content: center;
    font-family: $title-font;
    font-size: $base-font-size-arabic;
    line-height: 1.25;
    text-transform: $button-text-transform;
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 70px;
    padding: 11px 16px 11px 35px;
    inline-size: 100%;
    outline: none;

    @include respond-max($tablet) {
      flex-basis: 100%;
    }

    @include respond-min($tablet) {
      inline-size: 260px;
      font-size: $button-font-size;
    }

    &::before {
      content: '';
      position: absolute;
      margin-block-end: 2px;
      block-size: 16px;
      inline-size: 16px;
      border: 2px solid $white;
      border-radius: 50%;
      inset-inline-start: 10px;
    }

    .layout--middle-east & {
      &::after {
        content: '';
        margin-block-end: 2px;
        block-size: 12px;
        inline-size: 16px;
        mask-image: url('/icons/bullet.svg');
        mask-size: cover;
        margin-inline-start: 8px;
        transition: all;
      }

      &:hover::after {
        animation: bounce-up 1.25s infinite;
      }
    }

    :deep(.product-range-filter__item-scribble) {
      position: absolute;
      font-size: 14px;
      inset-inline-start: 11px;

      path {
        stroke-dasharray: 60;
        stroke-dashoffset: 60;
        transition: all 0.3s linear;
        transition-delay: 0.15s;
      }

      polyline {
        stroke-dasharray: 22;
        stroke-dashoffset: 42;
        transition: all 0.2s linear;
      }
    }

    &--active :deep(.product-range-filter__item-scribble) {
      path {
        stroke-dashoffset: 0;
      }

      polyline {
        stroke-dashoffset: 66;
      }
    }

    // Europe
    &--tasty-shapes-seasoned {
      :deep(.product-range-filter__item-scribble) path {
        stroke: $category-tasty-shapes-seasoned;
      }
    }

    &--tasty-shapes-natural {
      :deep(.product-range-filter__item-scribble) path {
        stroke: $category-tasty-shapes-natural;
      }
    }

    &--specialities {
      :deep(.product-range-filter__item-scribble) path {
        stroke: $category-specialities;
      }
    }

    // Middle East
    &--family::after {
      background-color: $category-family;
    }

    &--special {
      &::after {
        background-color: $category-special;
      }
    }

    &--social::after {
      background-color: $category-social;
    }

    &--breakfast {
      &::after {
        background-color: $category-breakfast;
      }
    }
  }
}

.layout--europe {
  .product-range-filter {
    &__item {
      padding: 15px 15px 15px 35px;

      &::before {
        margin: 0;
        position: absolute;
        block-size: 32px;
        inline-size: 32px;
      }
    }

    &__item-scribble {
      display: flex;
      align-items: center;
      font-size: 34px;
      inline-size: 35px;
      block-size: 100%;
      inset-inline-start: 10px;
      inset-block-start: 4px;
    }
  }
}
</style>
