import { type ProductFilter } from '@/types/Product';

const filtersEurope = {
  tasty_shapes_seasoned: {
    value: false,
  },
  tasty_shapes_natural: {
    value: false,
  },
  homestyle: {
    value: false,
  },
  specialities: {
    value: false,
  },
};

const filtersMiddleEast = {
  family: {
    value: false,
  },
  special: {
    value: false,
  },
  social: {
    value: false,
  },
  breakfast: {
    value: false,
  },
};

const productFilters: { [key: string]: ProductFilter } = {
  europe: filtersEurope,
  'middle-east': filtersMiddleEast,
};

export default productFilters;
