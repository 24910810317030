<template>
  <div class="product-swiper" :style="{ '--rotate-deg': `${rotationDeg}deg` }">
    <Swiper
      :key="swiperKey"
      ref="swiper"
      class="product-swiper__element"
      :dir="i18n.localeProperties.value.dir"
      data-test="product-swiper"
      :speed="400"
      v-bind="swiperParams"
      @swiper="onSwiper"
      @slide-change="slideAnimation"
    >
      <SwiperSlide
        v-for="(productsSubArray, index) in productsArray"
        :key="index"
        class="product-swiper__slide"
        :class="{ 'fade-out-in-animation': isAnimating }"
      >
        <ProductCard
          v-for="(product, cardIndex) in productsSubArray"
          :key="product.uuid"
          :product="product"
          :z-index="cardIndex as number"
          class="product-swiper__product-card"
        />
      </SwiperSlide>

      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div class="product-swiper__controls">
        <div slot="pagination" class="product-swiper__pagination" />
        <div v-show="productsArray.length > 1" class="product-swiper__navigation">
          <NuxtIcon
            slot="button-prev"
            name="circle-prev"
            filled
            class="product-swiper__prev product-swiper__button swiper-pagination-prev"
            :class="{ 'product-swiper__button--disabled': swiperRef?.isBeginning }"
            @click="changeSlide(Direction.Previous)"
          />
          <NuxtIcon
            slot="button-next"
            name="circle-next"
            filled
            class="product-swiper__next product-swiper__button swiper-pagination-next"
            :class="{ 'product-swiper__button--disabled': swiperRef?.isEnd }"
            @click="changeSlide(Direction.Next)"
          />
        </div>
      </div>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import {
  computed, type PropType, ref, watch,
} from 'vue';
import { useNuxtApp } from 'nuxt/app';
import { type Swiper as SwiperRef } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';

import { useRuntimeConfig } from '#app';
import ProductCard from '@/components/product/ProductCard.vue';
import useUIHelper from '@/composables/useUIHelper';
import { type Product } from '@/types/Product';
import Direction from '@/constants/Direction';
import { MIDDLE_EAST } from '@/constants/Themes';

const props = defineProps({
  products: {
    type: Array as PropType<Product[]>,
    default: () => [],
  },
});

const { isMobileDevice, isTabletDevice } = useUIHelper();
const { $bus } = useNuxtApp();
const config = useRuntimeConfig();

const i18n = useI18n();
const swiper = ref(null);
const swiperRef = ref<SwiperRef>(null);
const swiperKey = ref(0);
const rotationDeg = ref(0);
const isAnimating = ref(false);

enum Rotation {
  Clockwise = 1,
  CounterClockwise = -1,
}

const swiperParams: any = {
  modules: [Navigation, Pagination],
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.product-swiper__next',
    prevEl: '.product-swiper__prev',
  },
  pagination: {
    el: '.product-swiper__pagination',
    clickable: true,
  },
};

const productsArray = computed(() => {
  const amount = isMobileDevice.value || isTabletDevice.value ? 3 : 5;

  // creates subarrays with the length of the amount of products inside the existing array
  return Array.from(
    { length: Math.ceil(props.products.length / amount) },
    (_, index) => props.products.slice(index * amount, index * amount + amount),
  );
});

// todo: remove function when swiper is fixed https://github.com/nolimits4web/swiper/issues/6653
const changeSlide = (direction: Direction): void => {
  // Do not change slide if swiper has not been rerendered yet
  if (swiperKey.value === 0) {
    return;
  }

  if (direction === Direction.Next) {
    swiperRef.value.slideNext();
  }

  if (direction === Direction.Previous) {
    swiperRef.value.slidePrev();
  }
};

const forceElementRerender = () => {
  swiperKey.value += 1;
};

const rotateBackground = (rotation: Rotation): void => {
  rotationDeg.value += 45 * rotation;
};

const onSwiper = (swiperInstance: SwiperRef): void => {
  swiperRef.value = swiperInstance;
  swiperInstance.onAny((event: string) => {
    if (event === 'navigationNext' || event === 'slideNextTransitionStart') {
      rotateBackground(Rotation.Clockwise);
    }

    if (event === 'navigationPrev' || event === 'slidePrevTransitionStart') {
      rotateBackground(Rotation.CounterClockwise);
    }
  });
};

const slideAnimation = (): void => {
  const swiperWrapper = swiper.value?.$el.querySelector('.swiper-wrapper');

  if (!swiperWrapper) {
    return;
  }

  swiperWrapper.style.transitionDelay = '100ms';
  isAnimating.value = true;

  setTimeout(
    () => {
      isAnimating.value = false;
      swiperWrapper.style.transitionDelay = '';
    },
    config.public.theme === MIDDLE_EAST ? 500 : 1000,
  );
};

watch(
  () => props.products.length,
  () => {
    forceElementRerender();
  },
);

$bus.$on('product-filter:change', (): void => {
  slideAnimation();
});
</script>

<style scoped lang="scss">
.product-swiper {
  max-inline-size: $product-swiper-max-width;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    background-image: url('/backgrounds/stone-round.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    block-size: 250px;
    margin: 0;
    inset: 0;
    inset-block-start: 880px;
    transition: transform 1s ease-in-out;
    transform: rotate(var(--rotate-deg)) scale(3);
    pointer-events: none;

    @include respond-min($tablet) {
      block-size: 700px;
      inset-block-start: 490px;
      transform: rotate(var(--rotate-deg)) scale(1.1);
    }

    @include respond-min($desktop) {
      inset-block-start: 400px;
      transform: rotate(var(--rotate-deg));
    }
  }

  :deep(.swiper-wrapper) {
    margin: 0 auto 58px;

    @include respond-min($tablet) {
      margin-block-end: 120px;
      flex-direction: row;
    }
  }

  &__element {
    display: block;
    padding-block: 60px 56px;

    @include respond-min($desktop-xl) {
      min-block-size: 994px;
      padding-block-start: 146px;
    }

    &.swiper-vertical {
      touch-action: pan-y;
    }
  }

  &__slide {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__product-card {
    inline-size: 100%;
    display: flex;
    justify-content: center;
    margin-block-end: 40px;
    animation: fade-in 0.5s ease-in-out;

    @include respond-min($tablet) {
      min-inline-size: 33%;
    }

    .layout--europe & {
      margin-block-end: 0;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin: 0 40px;
    animation: fade-in 0.5s ease-in-out;
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  &__pagination {
    display: flex;
    justify-content: flex-start;
    inline-size: 100%;

    @include respond-min($tablet) {
      justify-content: center;
      margin-inline-start: 40px;
    }
  }

  &__button {
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__prev {
    @include respond-min($tablet) {
      position: absolute;
      inset-inline-start: 40px;
    }
  }
}

.layout--middle-east {
  .product-swiper {
    &::before {
      filter: blur(20px);
    }

    &::after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      margin: 0;
      inset: 0;
      pointer-events: none;
    }

    &::before,
    &::after {
      background-image: url('/backgrounds/star.webp');
      transform: none;
      block-size: 600px;
      inset-block-start: 750px;
      inset-inline-start: -100px;

      @include respond-min($tablet) {
        block-size: 800px;
        inset-block-start: 600px;
      }

      @include respond-min($desktop) {
        inset-block-start: 500px;
      }
    }

    &__element {
      @include respond-min($tablet) {
        min-block-size: 894px;
        padding-block-start: 130px;
      }
    }

    :deep(.swiper-wrapper) {
      @include respond-min($tablet) {
        margin-block-end: 30px;
      }
    }
  }
}
</style>
