<template>
  <div class="product-card">
    <BaseLink :url="product.routePath" class="product-card__link">
      <BaseImage
        v-if="product.hero_main_image"
        :image="product.hero_main_image"
        class="product-card__image"
        :style="{ 'z-index': zIndex * 3 + 2 }"
      />
      <BaseImage
        v-if="product.hero_secondary_image"
        :image="product.hero_secondary_image"
        class="product-card__secondary-image"
        :style="{ 'z-index': zIndex * 3 + 1 }"
      />
      <div class="product-card__more-info" :style="{ 'z-index': zIndex * 3 + 3 }">
        <span class="product-card__more-info-text">{{ $t('general.see_product') }}</span>
        <NuxtIcon
          v-if="config.public.theme === MIDDLE_EAST"
          filled
          name="product-category"
          :class="`product-card__category product-card__category--${product.category}`"
        />
        <NuxtIcon v-else name="arrow-right" filled class="product-card__arrow" />
      </div>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app';
import { type PropType } from 'vue';
import BaseImage from '@/components/base/BaseImage.vue';
import { MIDDLE_EAST } from '@/constants/Themes';
import { type Product } from '@/types/Product';

defineProps({
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
  zIndex: {
    type: Number,
    default: 0,
  },
});

const config = useRuntimeConfig();
</script>

<style lang="scss" scoped>
.product-card {
  display: flex;
  justify-content: center;
  align-self: center;
  inline-size: 100%;
  max-inline-size: 100%;
  block-size: 272px;

  @include respond-min($desktop) {
    max-inline-size: 182px;
    max-block-size: 300px;
  }

  @include respond-min($desktop) {
    max-block-size: 272px;
    block-size: auto;

    &:hover {
      .product-card__image {
        filter: brightness(0.7);
        transform: rotate(8deg);

        [dir='rtl'] & {
          transform: rotate(-8deg);
        }
      }

      .product-card__secondary-image {
        transform: translateY(-150px) rotate(110deg);

        [dir='rtl'] & {
          transform: translateY(-150px) rotate(-110deg);
        }
      }

      .product-card__more-info {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__link {
    position: relative;
    display: block;
    inline-size: 100%;
  }

  &__more-info-text {
    white-space: nowrap;
  }

  &__more-info {
    @include exception-left;

    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    inline-size: 236px;
    font-family: $title-font;
    font-size: to-rem(64);
    line-height: to-rem(80);
    text-transform: uppercase;
    text-align: center;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .layout--europe & {
      text-transform: none;
      line-height: 1;
      font-size: to-rem(24);
      font-weight: 700;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    inline-size: 40px;
    block-size: 40px;
    border: 2px solid $white;
    border-radius: 50%;
  }

  &__category {
    font-size: 12px;
    align-self: flex-end;
    margin-block-end: -17px;

    :deep(svg) {
      fill: currentcolor;
    }
  }

  &__category--family {
    color: $category-family;
  }

  &__category--special {
    color: $category-special;
  }

  &__category--social {
    color: $category-social;
  }

  &__category--breakfast {
    color: $category-breakfast;
  }

  &__image {
    position: relative;
    block-size: 100%;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  }

  &__secondary-image {
    display: none;

    @include respond-min($desktop) {
      display: block;
      transition: transform 0.3s ease-in-out;
      block-size: 180px;
      margin: 0 auto;
      position: absolute;
      inset-block-end: 40px;
      inset-inline-start: 80px;
      transform: rotate(110deg);

      [dir='rtl'] & {
        transform: rotate(-110deg);
      }
    }

    @include respond-min($desktop-xl) {
      inset-block-end: 60px;
    }
  }
}
</style>
