<template>
  <div class="product-range">
    <div class="product-range__content" data-test="product-range">
      <div class="layout__row">
        <div class="product-range__header">
          <span v-if="theme !== MIDDLE_EAST" class="product-range__header-subtitle heading heading--h5">
            {{ $t('product_range.subtitle') }}
          </span>
          <h2 v-if="showOverviewButton" class="product-range__header-title heading heading--h1">
            {{ $t('product_range.title') }}
          </h2>
          <h1 v-else class="product-range__header-title heading">
            {{ $t('product_range.title') }}
          </h1>
        </div>
        <ProductRangeFilters
          v-if="Object.keys(filtersWithProducts).length > 1"
          :value="filters"
          @input="setFilters($event)"
        />
      </div>

      <ProductSwiper :products="filteredProducts" />

      <div v-if="showOverviewButton" class="product-range__footer layout__row">
        <BaseLink :url="{ name: 'products' }" class="product-range__cta underlined-cta">
          {{ $t('general.link.product_overview') }}
        </BaseLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
import { useRoute, useRouter, useRuntimeConfig } from 'nuxt/app';
import useCurrentCountry from '@/composables/useCurrentCountry';
import productFilters from '@/constants/productFilters';
import { type Product, type ProductFilter } from '@/types/Product';
import ProductRangeFilters from '@/components/product/ProductRangeFilters.vue';
import ProductSwiper from '@/components/product/ProductSwiper.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import { MIDDLE_EAST } from '@/constants/Themes';

const config = useRuntimeConfig();
const { theme } = config.public;

const props = defineProps({
  products: {
    type: Array as PropType<Product[]>,
    default: () => [],
  },
  showOverviewButton: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
});

const filterItems = productFilters[theme as string];

const route = useRoute();
const filters = computed((): ProductFilter => {
  const filterValues = JSON.parse(JSON.stringify(filterItems));
  const categories: string[] = route.query.category ? route.query.category?.split(',') : [];
  categories.forEach((category: string) => {
    filterValues[category].value = true;
  });
  return filterValues;
});

const filteredProductsCountry = computed(() => {
  const allProducts = props.products;
  const { currentCountry } = useCurrentCountry();
  if (currentCountry) {
    return allProducts.filter((product) => product.countries?.includes(currentCountry));
  }
  return allProducts;
});

const filtersWithProducts = computed(() => {
  const categories = [...new Set(filteredProductsCountry.value.map((product) => product.category))];
  const newFilters = JSON.parse(JSON.stringify(filters.value));
  Object.keys(newFilters).forEach((key) => {
    if (!categories.includes(key)) {
      delete newFilters[key];
    }
  });
  return newFilters;
});

const filteredProducts = computed(() => {
  if (Object.values(filters.value).some((filter) => filter.value)) {
    return filteredProductsCountry.value.filter(
      (product: Product) => filters.value[product.category as keyof ProductFilter]?.value,
    );
  }
  return filteredProductsCountry.value;
});

const router = useRouter();
const setFilters = (newFilters: any) => {
  const categories = Object.keys(newFilters).filter((key) => newFilters[key]?.value === true);
  const categoryParam = categories.join(',');
  const query = categoryParam ? { category: categoryParam } : {};
  router.push({ query });
};
</script>

<style lang="scss" scoped>
.product-range {
  &__content {
    position: relative;
    background-color: $primary-color-800;
    margin-block-start: -1px;
    inline-size: 100vw;
    overflow: hidden;
  }

  &__header {
    text-align: center;
    color: $white;
  }

  &__header-subtitle {
    margin-block-end: 21px;
    font-weight: 250;
  }

  &__header-title {
    margin: 0 0 48px;

    @include respond-min($tablet) {
      margin-block-end: 85px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}

.layout--middle-east {
  .product-range {
    &__content {
      padding-block-start: 40px;

      @include respond-min($desktop) {
        padding-block-start: 150px;
      }
    }
  }
}
</style>
